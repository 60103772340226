import './App.css';

function App() {
  return (
    <div className="App bg-gray-100 min-h-screen flex flex-col">
      <header className="App-header bg-blue-600 text-white p-6">
        <h1 className="text-4xl font-bold">Tony Ma</h1>
      </header>
      <div className="flex justify-center py-6">
        <img 
          src={`${process.env.PUBLIC_URL}/meme_everything_is_fine.jpg`} 
          alt="meme" 
          className="w-64 h-64 rounded-lg shadow-md transition-transform transform hover:scale-105" 
        />
      </div>
      <section className="Education p-6">
        <h2 className="text-2xl font-semibold mb-4">Education</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>The Hang Seng University of Hong Kong - BSc. in Applied and Human-Centred Computing</li>
          <li>The Hong Kong Polytechnic University - MSc. in Information Technology</li>
          {/* Add more education details here */}
        </ul>
      </section>
      <footer className="Contact p-6 bg-blue-600 text-white mt-auto">
        <h2 className="text-2xl font-semibold mb-2">Contact Me</h2>
        <p className="text-black">Email: <a href="mailto:mamanto163@gmail.com" className="underline">mamanto163@gmail.com</a></p>
      </footer>
    </div>
  );
}

export default App;
